<template>
  <v-container
    id="regular-tables-view"
    fluid
    tag="section"
  >
    <item-management
      ref="itemManagement"
    >
    </item-management>
    <v-dialog
      v-model="dialog"
      max-width="500px"
    >
      <!--
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="primary"
          dark
          class="mb-2"
          v-bind="attrs"
          v-on="on"
        >
          New Item
        </v-btn>
      </template>
      -->
      <v-card>
        <v-card-title>
          <span class="headline">Create Item Type</span>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col
              cols="12"
              md="4"
            >
              <v-text-field
                label="Item Type"
                hint="HPESmart Memory / TrueDDR4"
                v-model="itemType.name"
              >
              </v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="4"
            >
              <v-select
                v-model="itemType.item_category_id"
                label="Item Category"
                :items="itemCategoryList"
                item-text="full_name"
                item-value="id"
              >
              </v-select>
            </v-col>
            <v-col
              cols="12"
              md="4"
            >
              <v-checkbox
                label="Is Request Check"
                v-model="itemType.request_check"
              >
              </v-checkbox>
            </v-col>
            <v-btn
              block
              color="teal"
              @click="createItemType"
            >
              Create
            </v-btn>
          </v-row>
        </v-card-text>
        <v-card-title>
          <span class="headline">Update Item </span>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col
              cols="12"
            >
              <v-text-field
                disabled
                v-model="editedItem.partNumber"
                label="Part Number"
              />
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-select
                :items="itemTypeList"
                v-model="editedItem.currentItemType"
                item-text="name"
                item-value="id"
                label="Current Type"
                disabled
              >
              </v-select>
            </v-col>
            <v-col
              cols="12"
              sm="6"
            >
              <v-select
                :items="itemTypeList"
                v-model="editedItem.newItemType"
                item-text="name"
                item-value="id"
                label="New Type"
              >
              </v-select>
            </v-col>
            <v-col
              cols="12"
            >
              <v-select
                v-model="editedItem.itemCategoryId"
                label="Item Category"
                :items="itemCategoryList"
                item-text="full_name"
                item-value="id"
              >
              </v-select>
            </v-col>
            <v-col
              cols="12"
            >
              <v-text-field
                v-model="editedItem.specifyKeyword"
                label="Specify Keyword"
              />
            </v-col>
            <v-btn
              color="accent"
              block
              @click="updateItemType"
            >
              Update
            </v-btn>
            <v-btn
              class="mt-2"
              color="info"
              block
              @click="close"
            >
              Cancel
            </v-btn>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-row>
      <v-col
        cols="12"
        md="9"
      >
        <material-card
          icon="mdi-clipboard-text"
          icon-small
          title="SOS Sync Item Table"
          color="accent"
        >
          <v-data-table
            :headers="itemTableHeader"
            :items="itemTableData"
            :items-per-page="20"
            sort-by="sos_id"
            class="elevation-1"
            style="font-size: small"
          >
            <template v-slot:top>
              <v-toolbar
                flat
              >
                <v-toolbar-title>Item List</v-toolbar-title>
                <v-divider
                  class="mx-4"
                  inset
                  vertical
                />
              </v-toolbar>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-icon
                small
                class="mr-2"
                @click="editItem(item)"
              >
                mdi-pencil
              </v-icon>
              <!--
              <v-icon
                small
                class="mr-2"
                @click="$router.push({ name: 'Item/Management', params: {id: item.id} }).catch(() => {})"
              >
                mdi-file-document-edit
              </v-icon>
              -->
              <v-icon
                small
                class="mr-2"
                @click="$refs.itemManagement.itemId = item.id.toString()"
              >
                mdi-file-document-edit
              </v-icon>
            </template>
          </v-data-table>
          <div
            v-if="!AdvancedFilterSwitch"
            class="text-center"
          >
            <v-pagination
              v-model="meta.current_page"
              :length="meta.last_page"
              circle
              :total-visible="7"
              @input="changePage"
            />
          </div>
          <div
            v-if="AdvancedFilterSwitch"
            class="text-center"
          >
            <v-pagination
              v-model="advancedSearchPagination.current_page"
              :length="advancedSearchPagination.last_page"
              circle
              :total-visible="7"
              @input="advancedSearchPageChange($event)"
            />
          </div>
        </material-card>
      </v-col>
      <v-col
        cols="12"
        md="3"
      >
        <material-card
          icon="mdi-pencil-plus-outline"
          icon-small
          title="SOS Sync Item Table"
          color="accent"
        >
          <v-card>
            <v-col cols="12">
              <v-text-field
                v-model="sosId"
                placeholder="Please enter SOS ID"
                label="Quick Sync Item From SOS"
              >
              </v-text-field>
              <v-btn
                @click="syncItemFromSos"
                :color="syncSosStatus"
              >
                Sync Item
              </v-btn>
            </v-col>
          </v-card>
        </material-card>
        <v-card-actions>
          <v-switch
            v-model="AdvancedFilterSwitch"
            :label="filterModelLabel"
          >
          </v-switch>
        </v-card-actions>
        <v-card
          v-if="AdvancedFilterSwitch === false"
          class="mt-2"
        >
          <v-card-title class="text-md-h5">
            Item Type Filter
          </v-card-title>
          <v-card-subtitle>
            - by Item Type (Recommend for Testing)
          </v-card-subtitle>
          <v-col
            cols="12"
          >
            <v-select
              v-model="itemTableFilter.itemTypeId"
              :items="itemTypes"
              item-text="displayText"
              item-value="id"
              label="Item Type"
            >
            </v-select>
          </v-col>
          <v-col
            cols="12"
          >
            <v-select
              v-model="itemTableFilter.order"
              :items="itemTableOrder"
              item-text="text"
              item-value="value"
              label="Order By"
            >
            </v-select>
          </v-col>
          <v-col
            cols="12"
          >
            <v-text-field
              v-model="itemTableFilter.keyWord"
              label="keyword"
              placeholder="Keyword If necessary"
              color="secondary"
              hide-details
            >
            </v-text-field>
          </v-col>
          <v-col cols="12">
            <v-btn
              small
              block
              @click="loadTableData"
            >
              Search
            </v-btn>
          </v-col>
        </v-card>
        <v-card
          v-if="AdvancedFilterSwitch === true"
          class="mt-2"
        >
          <v-card-title class="text-md-h5">
            Advanced Filter
          </v-card-title>
          <v-card-subtitle>
            - by Category and Property
          </v-card-subtitle>
          <v-col cols="12">
            <v-autocomplete
              v-model="itemCategoryFilter.item_category_id"
              :items="itemCategoryList"
              item-text="full_name"
              item-value="id"
              dense
              label="Select Item Category"
              @change="advancedSearch(itemCategoryFilter)"
            ></v-autocomplete>
            <v-btn
              block
              small
              @click="resetAdvancedSearch()"
            >
              Reset Filter
            </v-btn>
          </v-col>
          <v-card
            v-for="(element) in advancedSearchProperties"
            :key="element.name"
            cols="12"
          >
            <v-card-text>
              <v-select
                v-model="advancedSearchPropertiesSelected[element.name]"
                chips
                multiple
                outlined
                :items="element.list"
                :label="element.name "
                @change="updateAdvancedSearch(element.name)"
              ></v-select>
            </v-card-text>
          </v-card>
        </v-card>
      </v-col>
    </v-row>
    <div class="py-3" />
  </v-container>
</template>

<script>
  import ItemApi from '@/api/item'
  import ItemTypeApi from '@/api/item-type'
  import ItemCategoryApi from '@/api/item-category'
  import store from '@/store'
  import { get } from 'vuex-pathify'
  import MaterialCard from '@/components/MaterialCard'
  import ItemManagement from '../components/item/Management'
  export default {
    name: 'ItemTable',
    components: { MaterialCard, ItemManagement },
    computed: {
      ...get('app', ['itemTypes']),
      filterModelLabel () {
        return this.AdvancedFilterSwitch ? 'Switch to Item type filter' : 'Switch to Advanced Filter'
      },
    },
    data () {
      return {
        // keyWord: null,
        AdvancedFilterSwitch: false,
        itemTableFilter: {
          keyWord: null,
          itemTypeId: 0,
          order: 'recent_updated',
        },
        itemCategoryFilter: {
          item_category_id: null,
          property_filter: [],
        },
        advancedSearchProperties: [],
        advancedSearchPropertiesSelected: {},
        advancedSearchPagination: {
          current_page: 1,
          last_page: 1,
          links: [],
        },
        itemTableOrder: [
          { text: 'Old Update', value: 'old_updated' },
          { text: 'Last Update', value: 'recent_updated' },
          { text: 'Recent Created', value: 'recent_created' },
        ],
        dialog: false,
        dialogDelete: false,
        // desserts: [],
        editedItem: {
          id: null,
          partNumber: '',
          currentItemType: 1,
          newItemType: null,
          itemCategoryId: '',
          specifyKeyword: '',
        },
        defaultItem: {
          id: null,
          partNumber: '',
          currentItemType: 1,
          newItemType: null,
          itemCategoryId: '',
          specifyKeyword: '',
        },
        itemTableHeader: [
          {
            text: 'ID',
            align: 'start',
            sortable: true,
            value: 'id',
          },
          { text: 'part_number', value: 'part_number', sortable: false },
          { text: 'description', value: 'description', sortable: false },
          { text: 'Type', value: 'item_type.name' },
          { text: 'Category', value: 'item_category.name' },
          { text: 'SOS ID', value: 'sos_id' },
          // { text: 'bin_id', value: 'bin_id', sortable: false },
          { text: 'bin_number', value: 'bin_number', sortable: false },
          // { text: 'Specify Keyword', value: 'specify_keyword', sortable: false },
          { text: 'Actions', value: 'actions', sortable: false },
        ],
        itemTableData: [],
        meta: {},
        itemType: {
          name: '',
          item_category_id: '',
          request_check: false,
        },
        itemTypeList: [],
        itemCategoryList: [],
        // itemTypeCategory: ['Memory', 'HDD', 'SSD', 'Storage', 'SFP', 'Networking', 'Processor', 'GPU'],
        sosId: '',
        syncSosStatus: 'primary',
      }
    },
    watch: {
      dialog (val) {
        if (val === true) this.loadItemCategoryList()
        val || this.close()
      },
    },
    created () {
      this.loadTableData()
      this.loadItemTypeData()
      this.loadItemCategoryList()
      store.set('app/getItemTypes')
    },
    methods: {
      loadItemCategoryList (keyword = 'all') {
        ItemCategoryApi.quickSearch(keyword).then(response => {
          this.itemCategoryList = response.data.data
        }).catch(error => {
          console.log(error)
        })
      },
      loadTableData () {
        ItemApi.index(this.itemTableFilter).then(response => {
          this.itemTableData = response.data.data
          this.meta = response.data.meta
        })
      },
      advancedSearch (data) {
        ItemApi.advancedSearch(data).then(response => {
          this.advancedSearchResultUpdate(response)
        })
      },
      advancedSearchResultUpdate (response) {
        if (typeof (response.data.data.items.data) === 'object') response.data.data.items.data = Object.values(response.data.data.items.data)
        this.itemTableData = response.data.data.items.data
        this.advancedSearchPagination.current_page = response.data.data.items.current_page
        this.advancedSearchPagination.last_page = response.data.data.items.last_page
        this.advancedSearchPagination.links = response.data.data.items.links
        if (this.itemCategoryFilter.property_filter.length === 0) {
          this.advancedSearchProperties = response.data.data.properties
          this.itemCategoryFilter.property_filter = []
        } else {
          response.data.data.properties.forEach((element, key) => {
            const previousFilter = this.itemCategoryFilter.property_filter.find(property => property.name === element.name)
            if (previousFilter) {
              const previousFilterList = this.advancedSearchProperties.find(propertyList => propertyList.name === previousFilter.name)
              response.data.data.properties[key] = previousFilterList
            }
          })
          this.advancedSearchProperties = response.data.data.properties
        }
      },
      updateAdvancedSearch (name) {
        console.log(this.advancedSearchPagination)
        const getExistIndex = this.itemCategoryFilter.property_filter.findIndex(object => object.name === name)
        if (getExistIndex >= 0) {
          this.itemCategoryFilter.property_filter[getExistIndex] = Object.assign({}, {
            name: name,
            list: this.advancedSearchPropertiesSelected[name],
          })
        } else {
          this.itemCategoryFilter.property_filter.push({
            name: name,
            list: this.advancedSearchPropertiesSelected[name],
          })
        }
        this.advancedSearch(this.itemCategoryFilter)
      },
      resetAdvancedSearch () {
        this.advancedSearchPropertiesSelected = Object.assign({}, {})
        this.itemCategoryFilter.property_filter = []
        this.advancedSearch(this.itemCategoryFilter)
      },
      advancedSearchPageChange (page) {
        const pageUrl = this.advancedSearchPagination.links[page].url
        ItemApi.advancedSearchPageData(pageUrl).then(response => {
          this.advancedSearchResultUpdate(response)
        }).catch(error => {
          store.set('message/storeMessage', {
            color: 'error',
            text: error.response.data.message,
            timeout: 3500,
          })
        })
      },
      loadItemTypeData () {
        ItemTypeApi.index(this.itemTableFilter).then(response => {
          this.itemTypeList = response.data.data
        })
      },
      createItemType () {
        ItemTypeApi.store(this.itemType).then(() => {
          this.loadItemTypeData()
        })
      },
      editItem (item) {
        const itemCategoryId = ''
        if (item.item_category !== null) {
          const itemCategoryId = item.item_category.id
        }
        this.editedItem.id = item.id
        this.editedItem.partNumber = item.part_number
        this.editedItem.currentItemType = item.item_type.id
        this.editedItem.itemCategoryId = itemCategoryId
        this.editedItem.specifyKeyword = item.specify_keyword
        console.log(item)
        this.dialog = true
      },
      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
        })
      },
      updateItemType () {
        let itemType = this.editedItem.currentItemType
        if (this.editedItem.newItemType !== null) itemType = this.editedItem.newItemType
        this.editedItem.item_type_id = itemType
        ItemApi.update(this.editedItem).then(() => {
          this.changePage(this.meta.current_page)
        }).catch(error => {
          console.log(error)
        })
        this.close()
      },
      changePage (page) {
        ItemApi.loadPageData(page, this.meta.path, this.itemTableFilter).then(response => {
          this.itemTableData = response.data.data
          this.meta = response.data.meta
        })
      },
      syncItemFromSos () {
        this.syncSosStatus = 'info'
        ItemApi.syncItemFromSos(this.sosId).then(response => {
          response.data.code === 200 ? this.syncSosStatus = 'primary' : this.syncSosStatus = 'red'
        }).catch(error => {
          console.log(error)
          this.syncSosStatus = 'red'
        }).finally(() => {
          this.sosId = ''
          this.loadTableData()
        })
      },
    },
  }
</script>

<style scoped>

</style>
